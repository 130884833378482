import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import Button from '../common/button/button';
import DateFormat from '../common/dateFormat/DateFormat';
import Aguardando from '../common/template/aguardando';
import LabelAndInputMask from '../common/form/labelAndInputMask';

import { 
	getListaSaldoUnidade, getListaSaldoVeiculo
} from './acessorioSaldoActions';
import FormatUtils from '../common/formatUtils/FormatUtils';

class AcessorioSaldo extends Component {

	state = {
		pesquisar: '',
		linhaSelecionada: null
	}

    componentWillMount() {
        this.props.getListaSaldoUnidade();
		this.props.getListaSaldoVeiculo();
    }

    render() {

		let totalVeiculos = 0;
		let totalUnidades = 0;
		this.props.listaSaldoVeiculo.map(item => {
			totalVeiculos += parseInt(item.saldo || 0);
		});

		this.props.listaSaldoUnidade.map(item => {
			totalUnidades += parseInt(item.saldo || 0);
		});

		let totalGeral = totalVeiculos + totalUnidades;

		return (
			<ContentCard>
				<ContentCardHeader>
					<div class='row'>
						{this.props.listaSaldoUnidade.map(item => (
							<div class='col-sm-2 col-6'>
								<div class='description-block border-right'>
									<span class='description-percentage text-success'>{totalGeral != 0 ? FormatUtils.formatarValorTela((parseInt(item.saldo || 0) * 100) / totalGeral, 2) : 0}%</span>
									<h3 class='description-header'>{item.saldo || 0}</h3>
									<span class='description-text'>{item.nome_unidade}</span>
								</div>
							</div>
						))}
					</div>
				</ContentCardHeader>
			
				<ContentCardHeader>
					<div class='row'>
						<div class='col-sm-4 col-4'>
							<div class='description-block border-right'>
								<span class='description-percentage text-success'>{totalUnidades != 0 ? FormatUtils.formatarValorTela((parseInt(totalUnidades || 0) * 100) / totalGeral, 2) : 0}%</span>
								<h3 class='description-header'>{totalUnidades}</h3>
								<span class='description-text'>UNIDADES</span>
							</div>
						</div>

						<div class='col-sm-4 col-4'>
							<div class='description-block border-right'>
								<span class='description-percentage text-success'>{totalGeral != 0 ? FormatUtils.formatarValorTela((parseInt(totalVeiculos || 0) * 100) / totalGeral, 2) : 0}%</span>
								<h3 class='description-header'>{totalVeiculos}</h3>
								<span class='description-text'>VEÍCULOS</span>
							</div>
						</div>

						<div class='col-sm-4 col-4'>
							<div class='description-block border-right'>
								<span class='description-percentage text-success'>100%</span>
								<h3 class='description-header'>{FormatUtils.formatarValorTela(parseInt(totalGeral), 0)}</h3>
								<span class='description-text'>TOTAL</span>
							</div>
						</div>
					</div>
				</ContentCardHeader>

				<ContentCardBody>
					<input className='form-control'
						 placeholder='Pesquisar'
						 type='text'
						 value={this.state.pesquisar}
						 onChange={e => {
							 this.setState({ ...this.state, pesquisar: e.target.value });
						 }} />

					<Table responsive>
						<THead>
							<Tr>
								<Th>Placa</Th>
								<Th alignCenter>Saldo</Th>
							</Tr>
						</THead>
						<TBody>
							{(this.props.listaSaldoVeiculo).filter(item => {
								if (this.state.pesquisar
									&& !(`${item.placa_veiculo}`).toUpperCase().includes(this.state.pesquisar.toUpperCase())) {
									return false;
								}
								return true;
							}).map(item => (
								<Tr key={item.id} className={`${this.state.linhaSelecionada == item.id ? 'hover' : ''}`}
									onClick={() => this.setState({ ...this.state, linhaSelecionada: item.id })}
									onMouseEnter={() => this.setState({ ...this.state, linhaSelecionada: item.id })} >
									<Td>{item.placa_veiculo}</Td>
									<Td alignCenter>{item.saldo || '-'}</Td>
								</Tr>
							))}
						</TBody>
					</Table>
				</ContentCardBody>
			</ContentCard>
		);
	}


}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	modoTela: state.acessorioSaldo.modoTela,
	filtro: state.acessorioSaldo.filtro,
	aguardando: state.acessorioSaldo.aguardando,
	listaSaldoUnidade: state.acessorioSaldo.listaSaldoUnidade,
	listaSaldoVeiculo: state.acessorioSaldo.listaSaldoVeiculo
});
const mapDispatchToProps = dispatch => bindActionCreators({ 
	getListaSaldoUnidade, getListaSaldoVeiculo
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(AcessorioSaldo);
