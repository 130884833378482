import React, { useState } from 'react'
import Grid from '../layout/grid'

export default props => {

	const [pesquisar, setPesquisar] = useState('');

	return (
		<Grid cols={props.cols}>
	        <div class='form-group'>
				{props.label &&
				<label htmlFor='pesquisa_geral'>{props.label}</label>}
				<div class='input-group' id='pesquisar'>
				<input 
					type='text'
					name='pesquisa_geral'
					class='form-control datetimepicker-input'
					data-target='#pesquisar'
					placeholder='Adicione + para combinar pesquisas'
					value={pesquisar}
					onChange={e => {
						setPesquisar(e.target.value);
					}}
					onKeyDown={e => {
						if (e.key === 'Enter') {
							props.onChange(pesquisar);
						}
					}} />
					<div type='button' class='input-group-append' data-target='#pesquisar'>
						<div 
							class='input-group-text'
							onClick={e => {
								props.onChange(pesquisar);
							}}>
							<i class='fas fa-search'></i>
						</div>
					</div>
				</div>
			</div>
	    </Grid>
	);
}
