import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import Button from '../common/button/button';
import Form from './usuarioForm';
import LabelAndCheckbox from '../common/form/labelAndCheckbox';

import { setModoTela, initForm, salvar, excluir, getLista, getListaPerfil, getListaNegociador, salvarPermissao, alterarSenha } from './usuarioActions';

class Usuario extends Component {

	state = {
		linhaSelecionada: null,
		pesquisar: ''
	}

    componentWillMount() {
        this.props.getLista();
		this.props.getListaPerfil();
		this.props.getListaNegociador();
    }

    render() {
        return (
            <div>
                <Content>
					{this.props.modoTela == 'cadastro' ? (
						<Form acessoCliente onSubmit={this.props.salvar} />
					) : null}
					{this.props.modoTela == 'alterarSenha' ? (
						<Form acessoCliente alterarSenha onSubmit={this.props.alterarSenha} />
					) : null}
					{this.props.modoTela == 'exclusao' ? (
						<Form acessoCliente excluir onSubmit={this.props.excluir} />
					) : null}
					{this.props.modoTela == 'lista' ? this.lista() : null}
                </Content>
            </div>
        )
    }

	lista() {
		return (
			<ContentCard>
				<ContentCardHeader>
					<Row>
						<Grid cols='6 6 4 2'>
							<Button
								text='Adicionar'
								type={'success'}
								icon={'fa fa-plus'}
								event={() => {
									this.props.setModoTela('cadastro', { 
										acesso_cliente: true,
										primeiro_acesso: true,
										id_perfil: 32 //Cliente
									});
									this.props.initForm({
										acesso_cliente: true,
										primeiro_acesso: true,
										id_perfil: 32 //Cliente 
									});
								}} />
						</Grid>
					</Row>
				</ContentCardHeader>
				<ContentCardBody>
					<input className='form-control'
						 placeholder='Pesquisar'
						 type='text'
						 value={this.state.pesquisar}
						 onChange={e => {
							 this.setState({ ...this.state, pesquisar: e.target.value });
						 }} />
					<Table responsive alturaFixa maxHeight={window.innerHeight - 200}>
						<THead>
							<Tr backgroundColor={'#fff'}>
								<Th width={150} sticky>Nome</Th>
								<Th width={150} sticky alignCenter>Login</Th>
								<Th width={300} sticky alignCenter>Negociadores</Th>
								<Th width={10}  sticky alignCenter>Reports</Th>
								<Th width={10}  sticky alignCenter>Track</Th>
								<Th width={10}  sticky alignCenter>App Cliente</Th>
								<Th width={10}  sticky alignCenter>Nacional</Th>
								<Th width={10}  sticky alignCenter>Internacional</Th>
								<Th width={10}  sticky alignCenter>Rumo Norte</Th>
								<Th width={10}  sticky alignCenter>Rumo Sul</Th>
								<Th width={10}  sticky alignCenter title='Indicadores no Track'>Ind. Track</Th>
								<Th width={100} sticky colspan={1}></Th>
							</Tr>
						</THead>
						<TBody>
							{this.props.lista.filter(item => item.acesso_cliente).filter(item => {

								let negociadores = (item.id_negociadores || []).map(item => {
									const negociador = this.props.listaNegociador.filter(itemAux => itemAux.id == item)[0];									
									return negociador ? negociador.valor : '';
								}).join(', ');

								if (this.state.pesquisar
									&& !(`${item.nome} ${item.login} ${negociadores || ''}`).toUpperCase().includes(this.state.pesquisar.toUpperCase())) {
									return false;
								}
								return true;
							}).map(item => (
								<Tr key={item.id} className={`${this.state.linhaSelecionada == item.id ? 'hover' : ''}`}
									onClick={() => this.setState({ ...this.state, linhaSelecionada: item.id })}
									onMouseEnter={() => this.setState({ ...this.state, linhaSelecionada: item.id })} >
									<Td>{item.nome}</Td>
									<Td alignCenter>{item.login}</Td>
									<Td alignCenter small noWrap>{
										(item.id_negociadores || []).map(item => {
											const negociador = this.props.listaNegociador.filter(itemAux => itemAux.id == item)[0];									
											return negociador ? negociador.valor : '';
										}).join(', ')
									}</Td>
									<Td alignCenter>
										<input
											type='checkbox'
											checked={item.reports}
											onChange={data => this.props.salvarPermissao({ ...item, reports: !item.reports })} />
									</Td>
									<Td alignCenter>
										<input
											type='checkbox'
											checked={item.track}
											onChange={data => this.props.salvarPermissao({ ...item, track: !item.track })} />
									</Td>
									<Td alignCenter minWidth={50}>
										<input
											type='checkbox'
											checked={item.app_cliente}
											onChange={data => this.props.salvarPermissao({ ...item, app_cliente: !item.app_cliente })} />
									</Td>
									<Td alignCenter>
										<input
											type='checkbox'
											checked={item.nacional}
											onChange={data => this.props.salvarPermissao({ ...item, nacional: !item.nacional })} />
									</Td>
									<Td alignCenter>
										<input
											type='checkbox'
											checked={item.internacional}
											onChange={data => this.props.salvarPermissao({ ...item, internacional: !item.internacional })} />
									</Td>
									<Td alignCenter>
										<input
											type='checkbox'
											checked={item.rumo_norte}
											onChange={data => this.props.salvarPermissao({ ...item, rumo_norte: !item.rumo_norte })} />
									</Td>
									<Td alignCenter>
										<input
											type='checkbox'
											checked={item.rumo_sul}
											onChange={data => this.props.salvarPermissao({ ...item, rumo_sul: !item.rumo_sul })} />
									</Td>
									<Td alignCenter>
										<input
											type='checkbox'
											checked={item.track_indicadores}
											onChange={data => this.props.salvarPermissao({ ...item, track_indicadores: !item.track_indicadores })} />
									</Td>
									<Td alignRight>
										<ButtonTable
											type={'warning'}
											icon={'fas fa-user-lock'}
											visible={this.state.linhaSelecionada == item.id}
											title='Alterar senha'
											event={() => {
												this.props.setModoTela('alterarSenha', {
													...item,
													primeiro_acesso: true,
													senha: null
												});
												this.props.initForm({
													...item,
													primeiro_acesso: true,
													senha: null
												});
											}} />
											
										<ButtonTable
											type={'warning'}
											icon={'fas fa-pencil-alt'}
											visible={this.state.linhaSelecionada == item.id}
											event={() => {
												this.props.setModoTela('cadastro', {
													...item
												});
												this.props.initForm({
													...item
												});
											}} />

										<ButtonTable
											type={'danger'}
											icon={'fas fa-trash-alt'}
											visible={this.state.linhaSelecionada == item.id}
											event={() => {
												this.props.setModoTela('exclusao', {
													...item,
													senha: null
												});
												this.props.initForm({
													...item,
													senha: null
												});
											}} />
									</Td>
								</Tr>
							))}
						</TBody>
					</Table>
				</ContentCardBody>
			</ContentCard>
		);
	}


}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	modoTela: state.usuario.modoTela,
	lista: state.usuario.lista,
	listaNegociador: state.usuario.listaNegociador
});
const mapDispatchToProps = dispatch => bindActionCreators({ setModoTela, initForm, salvar, excluir, getLista, getListaPerfil, getListaNegociador, salvarPermissao, alterarSenha }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Usuario);
