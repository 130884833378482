import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import ContentCardFooter from '../common/template/contentCardFooter';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Button from '../common/button/button';
import Form from '../common/form/form';
import LabelAndInput from '../common/form/labelAndInput';
import LabelAndCheckbox from '../common/form/labelAndCheckbox';
import Select from '../common/form/select';
import SelectDialog from '../common/form/selectDialog';
import { setError, setSuccess } from '../common/toast/toast';
import consts from '../consts';
import { setModoTela, initForm } from './usuarioActions';
import ButtonTable from '../common/table/buttonTable';
import LabelAndInputMask from '../common/form/labelAndInputMask';
import FormatUtils from '../common/formatUtils/FormatUtils';
import imagemAdd from '../assets/images/icone_add_imagem.png';
import pdfAdd from '../assets/images/icone_add_pdf.png';
import pdfAdicionado from '../assets/images/icone_pdf_adicionado.png';


class UsuarioForm extends Component {

	state = { placa: '' }

	componentDidMount() {

		const fileSelectorLogo = document.createElement('input');
		fileSelectorLogo.setAttribute('type', 'file');
		fileSelectorLogo.addEventListener("change", (data) => {

			let reader = new FileReader();
			let file = data && data.path && data.path[0] && data.path[0].files && data.path[0].files[0] ? data.path[0].files[0] : null;
			if (!file) {
				file = data && data.target && data.target.files && data.target.files[0] ? data.target.files[0] : null;
			}

			if (file) {

				if (!file.type.includes('image/png') && !file.type.includes('image/jpg') && !file.type.includes('image/jpeg')) {
					setError('Selecione apenas (.PNG, .JPG e .JPEG)');
					return;
				}

				reader.onloadend = () => {

					let lista = (this.props.formularioValues.logo || []).map(item => item);
					lista.push({
						arquivo_base64: reader.result
					});

					this.props.initForm({
						...this.props.formularioValues,
						logo: lista
					});

				}

				reader.readAsDataURL(file);

			}
		});

		this.fileSelectorLogo = fileSelectorLogo;

	}

    componentWillMount() {

    }

    render() {

		let alterarSenha = this.props.alterarSenha;
		let readOnly = this.props.excluir || alterarSenha ? 'readOnly' : '';
		let acessoCliente = this.props.acessoCliente;

        return (
			<ContentCard>
				<Form event={this.props.handleSubmit}>
					<ContentCardBody>
						<Row>
							<Field
								name='nome'
								component={LabelAndInput}
								label='Nome *' placeholder='Informe o Nome'
								cols='12 12 12 12'
								readOnly={readOnly} />

							<Field
								name='login'
								component={LabelAndInput}
								label='Login *'
								placeholder='Informe o Usuário'
								cols='12 12 12 12'
								readOnly={readOnly} />

							{(alterarSenha || !this.props.formularioValues.id) && 
							<Field
								name='senha'
								component={LabelAndInput}
								type="password"
								label='Senha *'
								placeholder='Informe a Senha'
								cols='12 12 12 12'
								readOnly={''} />}

							{!acessoCliente &&
							<Field
								name='id_perfil'
								component={Select}
								options={this.props.listaPerfil}
								label='Perfil'
								cols='12 12 12 12'
								placeholder='Selecione o perfil'
								readOnly={readOnly} />}
								
							{!acessoCliente &&
							<Field
								name='comissao_variavel'
								component={LabelAndCheckbox}
								label='Comissão Variável'
								cols='12 12 12 12'
								placeholder='Selecione'
								readOnly={readOnly} />}
							
							{acessoCliente &&
							<SelectDialog
								options={this.props.listaNegociador}
								label='Negociadores'
								cols='12 12 12 12'
								placeholder='Selecione os negociadores'
								multiple
								readOnly={readOnly}
								value={(this.props.formularioValues.id_negociadores || [])}
								onChange={value => {
									this.props.initForm({
										...this.props.formularioValues,
										id_negociadores: (value || [])
									});
								}} />}
								
						</Row>

						{(this.props.formularioValues.id && !acessoCliente) &&
						<Row>

							<LabelAndInput
								label='Placa CCO'
								placeholder='Informe a placa'
								cols='6 4 3 2'
								readOnly={readOnly}
								value={this.state.placa}
								onChange={(data) => this.setState({ ...this.state, placa: data.target.value })} />
								
							<div style={{ paddingTop: 29, paddingBottom: 16 }}>
								<ButtonTable
									type={'primary'}
									icon={'fas fa-plus'}
									visible={!readOnly}
									event={() => {
										let placas = (this.props.formularioValues.placas_cco || []).map(item => item);

										if (!(this.state.placa || '').trim().length) {
											setError('Placa não informada');
										} else if (placas.filter(item => item == this.state.placa).length) {
											setError('Placa já informada');
										} else {
											placas.push(this.state.placa);
											this.props.initForm({
												...this.props.formularioValues,
												placas_cco: placas
											});
											setSuccess('Placa adicionada com sucesso');
											this.setState({ ...this.state, placa: '' })
										}
									}} />
							</div>
						</Row>}

						{this.props.formularioValues.id &&
						<Row>

							{(this.props.formularioValues.placas_cco || []).map(item => (
								<div key={item} class='card-tools' style={{ marginLeft: 8, marginTop: 8, padding: 8, backgroundColor: '#d4e9ff', borderRadius: 10, minWidth: 150, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
									{item}
									<a class='btn btn-sm btn-tool'>
										<i class='fas fa-trash'
											onClick={() => this.props.initForm({
												...this.props.formularioValues,
												placas_cco: (this.props.formularioValues.placas_cco || []).filter(placa => placa != item)
											})}></i>
									</a>
								</div>
							))}

						</Row>}

						{acessoCliente &&
						<Row>

							<LabelAndInput
								label='Contato'
								placeholder='Informe o nome do contato'
								cols='6 4 3 2'
								readOnly={readOnly}
								value={this.state.nome_contato}
								onChange={(data) => this.setState({ ...this.state, nome_contato: data.target.value })} />
							
							<LabelAndInputMask
								label='Whatsapp'
								placeholder='Informe o whatsapp'
								cols='6 4 3 2'
								readOnly={readOnly}
								mask={'(99) 9 9999-9999'}
								value={this.state.telefone_contato}
								onChange={(data) => this.setState({ ...this.state, telefone_contato: data.target.value })} />
								
							<div style={{ paddingTop: 29, paddingBottom: 16 }}>
								<ButtonTable
									type={'primary'}
									icon={'fas fa-plus'}
									visible={!readOnly}
									event={() => {
										let contatos = (this.props.formularioValues.contatos || []).map(item => item);

										if (!(this.state.nome_contato || '').trim().length) {
											setError('Contato não informado');
										} else if (!(this.state.telefone_contato || '').trim().length) {
											setError('Whatsapp não informado');
										} else if ((FormatUtils.removerMascara(this.state.telefone_contato || '')).trim().length != 11) {
											setError('Whatsapp incorreto');
										} else {
											contatos.push({
												id: new Date().getTime(),
												nome: this.state.nome_contato,
												telefone: FormatUtils.removerMascara(this.state.telefone_contato)
											});
											this.props.initForm({
												...this.props.formularioValues,
												contatos: contatos
											});
											this.setState({ ...this.state, nome_contato: '', telefone_contato: '' })
										}
									}} />
							</div>
						</Row>}

						{acessoCliente &&
						<Row>

							{(this.props.formularioValues.contatos || []).map(item => (
								<div key={item.id} class='card-tools' style={{ marginLeft: 8, marginTop: 8, padding: 8, backgroundColor: '#d4e9ff', borderRadius: 10, minWidth: 150, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
									{item.nome} - {FormatUtils.formatarTelefone(item.telefone)}
									<a class='btn btn-sm btn-tool'>
										<i class='fas fa-trash'
											onClick={() => this.props.initForm({
												...this.props.formularioValues,
												contatos: (this.props.formularioValues.contatos || []).filter(contato => contato.id != item.id)
											})}></i>
									</a>
								</div>
							))}

						</Row>}

						{acessoCliente && 
						<Row style={{ padding: 8 }}>
							<Grid cols='12 12 12 12'>
								<label>Logo</label>
							</Grid>
							{(this.props.formularioValues.logo || []).map(arquivo => (
								<div style={{ display: 'flex', flexDirection: 'column' }}>
									<span class="btn btn-outline-primary fileinput-button dz-clickable">
										<img src={arquivo.arquivo_base64 ? imagemAdd : (arquivo.arquivo ? imagemAdd : pdfAdd)} style={{ height: 30 }} />
									</span>
									<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', paddingRight: 2, marginTop: 4 }}>
										<button
											type='button'
											class='btn btn-flat btn-outline-primary'
											style={{ width: '48%' }}
											disabled={arquivo.arquivo_base64 ?true : false}
											onClick={() => {
												window.open(`${consts.URL}/${arquivo.arquivo}`, '_blank');
											}}>
											<i class='fas fa-download'></i>
										</button>

										<button
											type='button'
											class='btn btn-flat btn-outline-danger'
											style={{ width: '48%' }}
											disabled={readOnly}
											onClick={() => {
												this.props.initForm({
													...this.props.formularioValues,
													logo: this.props.formularioValues.logo.filter(item => item != arquivo)
												});
											}}>
											<i class='fas fa-trash-alt'></i>
										</button>
									</div>
								</div>
							))}
							{(!readOnly && (this.props.formularioValues.logo || []).length == 0) ? (
								<span class="btn btn-outline-primary fileinput-button dz-clickable" onClick={() => this.fileSelectorLogo.click()}>
									<img src={imagemAdd} style={{ height: 30 }} />
								</span>
							) : null}
					</Row>}
					</ContentCardBody>
					<ContentCardFooter>
						<Row alignCenter>
							<Grid cols='6 6 4 3'>
								{this.props.excluir ? (
									<Button
										text='Excluir'
										submit type={'danger'}
										icon={'fa fa-trash'} />
								) : (
								<Button
									text='Salvar'
									submit
									type={'success'}
									icon={'fa fa-check'} />
								)}
							</Grid>
							<Grid cols='6 6 4 3'>
								<Button
									text='Voltar'
									type={'warning'}
									icon={'fa fa-chevron-left'}
									event={() => this.props.setModoTela('lista')} />
							</Grid>
						</Row>
					</ContentCardFooter>

				</Form>
			</ContentCard>
        )
    }


}

UsuarioForm = reduxForm({form: 'usuarioForm', destroyOnUnmount: false})(UsuarioForm);
const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	formularioValues: getFormValues('usuarioForm')(state),
	registro: state.usuario.registro,
	listaPerfil: state.usuario.listaPerfil,
	listaNegociador: state.usuario.listaNegociador
});
const mapDispatchToProps = dispatch => bindActionCreators({ setModoTela, initForm }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(UsuarioForm);
