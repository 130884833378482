import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import ContentCardFooter from '../common/template/contentCardFooter';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Button from '../common/button/button';
import Form from '../common/form/form';
import LabelAndInput from '../common/form/labelAndInput';
import LabelAndInputNumber from '../common/form/labelAndInputNumber';
import LabelAndInputMask from '../common/form/labelAndInputMask';
import LabelAndInputDateTime from '../common/form/labelAndInputDateTime';
import Select from '../common/form/select';
import SelectAlteracao from '../common/form/selectAlteracao';

import {
	setModoTela, initForm
} from './acessorioSaidaActions';

class AcessorioSaidaForm extends Component {

	state = {

	}

    componentWillMount() {

    }

    render() {

		let readOnly = this.props.excluir ? 'readOnly' : '';

        return (
			<ContentCard>
				<Form event={this.props.handleSubmit}>
					<ContentCardBody>
						<Row>

							<Field
								name='id_tipo'
								component={Select}
								options={this.props.listaTipo}
								label='Tipo'
								cols='12 12 12 12'
								placeholder='Selecione o tipo'
								readOnly={readOnly} />
								
							<Field
								name='id_evento'
								component={Select}
								options={this.props.listaEvento}
								label='Evento'
								cols='12 12 12 12'
								placeholder='Selecione o evento'
								readOnly={readOnly} />
							
							{!this.props.formularioValues.id_veiculo ? (
								<Field
									name='id_unidade'
									component={Select}
									options={this.props.listaUnidade.filter(item => {

										if (this.props.sessao && this.props.sessao.id && this.props.sessao.id == 49) {
											return item.id == 4 || item.id == 7;
										} else if (this.props.sessao && this.props.sessao.id && this.props.sessao.id == 55) {
											return item.id == 6 || item.id == 9;
										} else {
											return true;
										}

									})}
									label='Unidade'
									cols='12 12 12 12'
									placeholder='Selecione a unidade'
									readOnly={this.props.formularioValues.id ? 'readonly' : readOnly} />
							) : null}
							{!this.props.formularioValues.id_unidade ? (
								<Field
									name='id_veiculo'
									component={Select}
									options={this.props.listaVeiculo}
									label='Veículo'
									cols='12 12 12 12'
									placeholder='Selecione o veículo'
									readOnly={this.props.formularioValues.id ? 'readonly' : readOnly} />
							) : null}

							{this.props.formularioValues.id_veiculo ? (
								<Field
									name='id_motorista'
									component={Select}
									options={this.props.listaMotorista}
									label='Motorista'
									cols='12 12 12 12'
									placeholder='Selecione o motorista'
									readOnly={readOnly} />
							) : null}
						
							<Field
								name='quantidade'
								component={LabelAndInputNumber}
								label='Quantidade' placeholder='Informe a quantidade'
								cols='12 12 4 4'
								casas={0}
								readOnly={readOnly} />

							<Field
								name='observacao'
								component={LabelAndInput}
								label='Observação' placeholder='Informe as observações'
								cols='12 12 12 12'
								readOnly={readOnly} />

						</Row>
					</ContentCardBody>
					<ContentCardFooter>
						<Row alignCenter>
							<Grid cols='6 6 4 3'>
								{this.props.excluir ? (
									<Button
										text='Excluir'
										submit type={'danger'}
										icon={'fa fa-trash'} />
								) : (
								<Button
									text='Salvar'
									submit
									type={'success'}
									icon={'fa fa-check'} />
								)}
							</Grid>
							<Grid cols='6 6 4 3'>
								<Button
									text='Voltar'
									type={'warning'}
									icon={'fa fa-chevron-left'}
									event={() => this.props.setModoTela('lista')} />
							</Grid>
						</Row>
					</ContentCardFooter>
				</Form>
			</ContentCard>
        )
    }


}

AcessorioSaidaForm = reduxForm({form: 'acessorioSaidaForm', destroyOnUnmount: false})(AcessorioSaidaForm);
const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	formularioValues: getFormValues('acessorioSaidaForm')(state),
	registro: state.acessorioSaida.registro,
	listaTipo: state.acessorioSaida.listaTipo,
	listaEvento: state.acessorioSaida.listaEvento,
	listaUnidade: state.acessorioSaida.listaUnidade,
	listaVeiculo: state.acessorioSaida.listaVeiculo,
	listaMotorista: state.acessorioSaida.listaMotorista
});
const mapDispatchToProps = dispatch => bindActionCreators({
	setModoTela, initForm
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(AcessorioSaidaForm);
