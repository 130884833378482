import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import Button from '../common/button/button';
import DateFormat from '../common/dateFormat/DateFormat';
import Aguardando from '../common/template/aguardando';
import LabelAndInputMask from '../common/form/labelAndInputMask';
import Select from '../common/form/select';

import { 
	setFiltro, getLista, getListaTipo, getListaUnidade, getListaVeiculo, getListaEvento
} from './acessorioHistoricoActions';
import FormatUtils from '../common/formatUtils/FormatUtils';

class AcessorioHistorico extends Component {

	state = {
		pesquisar: '',
		linhaSelecionada: null
	}

    componentWillMount() {
		this.props.getLista();
		this.props.getListaEvento();
		this.props.getListaTipo();
		this.props.getListaUnidade();
		this.props.getListaVeiculo();		
    }

    render() {

		
		return (
			<Content>

				<Aguardando aguardando={this.props.aguardando} />

				<ContentCard>
					<ContentCardHeader>
						<Row>
							<Grid cols='6 6 4 2'>
								<Button
									text='Atualizar'
									type={'primary'}
									icon={'fa fa-plus'}
									event={() => {
										this.props.getLista();
									}} />
							</Grid>
						</Row>
					</ContentCardHeader>
					<ContentCardHeader>
						<Row>
							<LabelAndInputMask
								label='Data/Hora' placeholder='Informe o período inicial'
								cols='12 6 3 2'
								mask='99/99/9999'
								value={this.props.filtro.data_inicial}
								onChange={(data) => {
									this.props.setFiltro({ ...this.props.filtro, data_inicial: data.target.value });
								}} />

							<LabelAndInputMask
								label='Data/Hora' placeholder='Informe o período final'
								cols='12 6 3 2'
								mask='99/99/9999'
								value={this.props.filtro.data_final}
								onChange={(data) => {
									this.props.setFiltro({ ...this.props.filtro, data_final: data.target.value });
								}} />
						</Row>
						<Row>
							<Select
								options={this.props.listaTipo}
								label='Acessório'
								cols='12 6 3 3'
								placeholder='Selecione o acessório'
								value={this.props.filtro.id_tipo}
								onChange={data => {
									this.props.setFiltro({ ...this.props.filtro, id_tipo: data });
								}} />

							<Select
								options={this.props.listaEvento}
								label='Evento'
								cols='12 6 3 3'
								placeholder='Selecione o evento'
								value={this.props.filtro.id_evento}
								onChange={data => {
									this.props.setFiltro({ ...this.props.filtro, id_evento: data });
								}} />
								
							<Select
								options={this.props.listaUnidade}
								label='Unidade'
								cols='12 6 3 3'
								placeholder='Selecione a unidade'
								value={this.props.filtro.id_unidade}
								onChange={data => {
									this.props.setFiltro({ ...this.props.filtro, id_unidade: data });
								}} />
								
							<Select
								options={this.props.listaVeiculo}
								label='Veículo'
								cols='12 6 3 3'
								placeholder='Selecione o veículo'
								value={this.props.filtro.id_veiculo}
								onChange={data => {
									this.props.setFiltro({ ...this.props.filtro, id_veiculo: data });
								}} />
						</Row>

					</ContentCardHeader>
				
					<ContentCardBody>
						<Table responsive>
							<THead>
								<Tr>
									<Th>Data/Hora</Th>
									<Th alignCenter>Acessório</Th>
									<Th alignCenter>Tipo</Th>
									<Th alignCenter>Evento</Th>
									<Th alignCenter>Unidade/Placa</Th>
									<Th alignCenter>Trf Origem/Destino</Th>
									<Th alignCenter>Motorista</Th>
									<Th alignCenter>Alteração</Th>
									<Th alignCenter>Anterior</Th>
									<Th alignCenter>Quantidade</Th>
									<Th alignCenter>Atual</Th>
								</Tr>
							</THead>
							<TBody>
								{this.props.lista.map(item => (
									<Tr key={item.id} className={`${this.state.linhaSelecionada == item.id ? 'hover' : ''}`}
										onClick={() => this.setState({ ...this.state, linhaSelecionada: item.id })}
										onMouseEnter={() => this.setState({ ...this.state, linhaSelecionada: item.id })} >
										<Td>{DateFormat.formatarDataHoraSqlParaTela(item.datahora_movimento)}</Td>
										<Td alignCenter>{item.nome_tipo}</Td>
										<Td alignCenter>{item.tipo_movimentacao}</Td>
										<Td alignCenter>{item.nome_evento}</Td>
										<Td alignCenter>{item.placa_veiculo || item.nome_unidade}</Td>
										<Td alignCenter>{item.placa_veiculo_trf || item.nome_unidade_trf}</Td>
										<Td alignCenter>{item.nome_motorista}</Td>
										<Td alignCenter>{item.nome_usuario}</Td>
										<Td alignCenter>{item.quantidade_inicial}</Td>
										<Td alignCenter>{item.quantidade}</Td>
										<Td alignCenter>{item.quantidade_final}</Td>
									</Tr> 
								))}
							</TBody>
						</Table>
					</ContentCardBody>
				</ContentCard>
			</Content>
		);
	}

}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	modoTela: state.acessorioHistorico.modoTela,
	filtro: state.acessorioHistorico.filtro,
	aguardando: state.acessorioHistorico.aguardando,
	lista: state.acessorioHistorico.lista,
	listaTipo: state.acessorioHistorico.listaTipo,
	listaEvento: state.acessorioHistorico.listaEvento,
	listaUnidade: state.acessorioHistorico.listaUnidade,
	listaVeiculo: state.acessorioHistorico.listaVeiculo
});
const mapDispatchToProps = dispatch => bindActionCreators({ 
	setFiltro, getLista, getListaTipo, getListaUnidade, getListaVeiculo, getListaEvento
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(AcessorioHistorico);
