import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import Button from '../common/button/button';
import Form from './motoristaForm';
import DateFormat from '../common/dateFormat/DateFormat';
import FormatUtils from '../common/formatUtils/FormatUtils';
import ReactExport from 'react-export-excel-fixed-xlsx';
import Select from '../common/form/select';
import Aguardando from '../common/template/aguardando';

import { setModoTela, initForm, salvar, excluir, getListaCpf, getListaGrupo } from './motoristaActions';

class MotoristaCpf extends Component {

	state = {
		linhaSelecionada: null,
		pesquisar: '',
		pontuacao: null,
		situacao: ''
	}

    componentWillMount() {
        this.props.getListaCpf();
    }

    render() {

		const ExcelFile = ReactExport.ExcelFile;
		const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
		const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

		let lista = this.props.listaCpf.filter(item => {
			if (this.state.pesquisar
				&& !(`${item.nome} ${FormatUtils.formatarCPF(item.cpf)} ${item.rg} ${DateFormat.formatarDataSqlParaTela(item.data_nascimento)}
					${item.nome_grupo} ${item.cpf_situacao || item.erro_cpf_infosimples} ${DateFormat.formatarDataSqlParaTela(item.datahora_cpf_infosimples)}`).toUpperCase().includes(this.state.pesquisar.toUpperCase())) {
				return false;
			}

			if (this.state.situacao
				&& !(`${item.cpf_situacao || item.erro_cpf_infosimples}`).toUpperCase().includes(this.state.situacao.toUpperCase())) {
				return false;
			}
			return true;
		});

		let listaSituacao = [];
		this.props.listaCpf.forEach(item => {
			if (!listaSituacao.filter(situacao => situacao.id == (item.cpf_situacao || item.erro_cpf_infosimples)).length) {
				listaSituacao.push({ id: item.cpf_situacao || item.erro_cpf_infosimples, valor: item.cpf_situacao || item.erro_cpf_infosimples })
			}
		});

		return (

			<Content>
				
				<Aguardando aguardando={this.props.aguardando} />
				
				<ContentCard>

					<ContentCardHeader>
						<Row>
							<Grid cols='6 6 3 2'>
								<ExcelFile
									filename={'Situação CPF'}
									element={
										<Button
											text='Exportar'
											type={'primary'}
											icon={'fa fa-plus'} />
									}>
									<ExcelSheet data={lista.map(item => ({
										...item,
										cpf: FormatUtils.formatarCPF(item.cpf),
										data_nascimento: DateFormat.formatarDataSqlParaTela(item.data_nascimento),
										cpf_situacao: item.cpf_situacao || item.erro_cpf_infosimples,
										datahora_cpf_infosimples: DateFormat.formatarDataSqlParaTela(item.datahora_cpf_infosimples)
									}))} name='cpf'>
										<ExcelColumn label='Nome' value='nome' />
										<ExcelColumn label='CPF' value='cpf' />
										<ExcelColumn label='RG' value='rg' />
										<ExcelColumn label='Data Nasc.' value='data_nascimento' />
										<ExcelColumn label='Grupo' value='nome_grupo' />
										<ExcelColumn label='Situação' value='cpf_situacao' />
										<ExcelColumn label='Ult Cons.' value='datahora_cpf_infosimples' />

									</ExcelSheet>
								</ExcelFile>
							</Grid>
						</Row>
					</ContentCardHeader>
					<ContentCardBody>
						<Row>
							<Select
								options={listaSituacao}
								label='Situação'
								cols='12 6 6 4'
								placeholder='Selecione a situação'
								value={this.state.situacao}
								onChange={data => {
									this.setState({ ...this.props.filtro, situacao: data });
								}} />
						</Row>

						<input className='form-control'
							placeholder='Pesquisar'
							type='text'
							value={this.state.pesquisar}
							onChange={e => {
								this.setState({ ...this.state, pesquisar: e.target.value });
							}} />
						<Table responsive>
							<THead>
								<Tr>
									<Th>Nome</Th>
									<Th alignCenter>CPF</Th>
									<Th alignCenter>RG</Th>
									<Th alignCenter>Data Nascimento</Th>
									<Th alignCenter>Grupo</Th>
									<Th alignCenter>Situação</Th>
									<Th alignCenter>Ult Cons.</Th>
								</Tr>
							</THead>
							<TBody>
								{lista.map(item => (
									<Tr key={item.id}>
										<Td small>{item.nome}</Td>
										<Td alignCenter small>{FormatUtils.formatarCPF(item.cpf)}</Td>
										<Td alignCenter small>{item.rg}</Td>
										<Td alignCenter small>{DateFormat.formatarDataSqlParaTela(item.data_nascimento)}</Td>
										<Td alignCenter small>{item.nome_grupo}</Td>
										<Td alignCenter small noWrap
											backgroundColor={item.cpf_situacao == 'REGULAR' ? '#81c784' : (!item.cpf_situacao ? null : '#f00')}
											color={item.cpf_situacao == 'REGULAR' ? '#fff' : (!item.cpf_situacao ? null : '#fff')}>{item.cpf_situacao || item.erro_cpf_infosimples}</Td>
										<Td alignCenter small>{DateFormat.formatarDataSqlParaTela(item.datahora_cpf_infosimples)}</Td>
									</Tr>
								))}
							</TBody>
						</Table>
					</ContentCardBody>
				</ContentCard>
			</Content>
		);
	}
}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	modoTela: state.motorista.modoTela,
	aguardando: state.motorista.aguardando,
	listaCpf: state.motorista.listaCpf
});
const mapDispatchToProps = dispatch => bindActionCreators({ setModoTela, initForm, salvar, excluir, getListaCpf, getListaGrupo }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(MotoristaCpf);
